import React from 'react';
import { Grid, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import TranslateIcon from '@mui/icons-material/Translate';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import RepartitionIcon from '@mui/icons-material/Repartition';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

function Header({ anchorEl, handleMenuOpen, handleMenuClose, path }) {
  const menuItems = [
    { path: '/', label: 'Translation', icon: <TranslateIcon sx={{paddingRight: '10px'}} /> },
    { path: '/transliteration', label: 'Transliteration', icon: <KeyboardIcon sx={{paddingRight: '10px'}} /> },
    { path: '/backtranslation', label: 'English to Darija', icon: <RepartitionIcon sx={{paddingRight: '10px'}} /> },
    { path: '/chat', label: 'Smart Assistant', icon: <MarkUnreadChatAltIcon sx={{paddingRight: '10px'}} /> },
    { path: '/about', label: 'About the project', icon: <ReceiptLongIcon sx={{paddingRight: '10px'}} /> },
    { path: '/darija-resources', label: 'Darija Resources', icon: <AutoAwesomeMotionIcon sx={{paddingRight: '10px'}} /> }
  ];

  return (
    <Grid className='Header'>
      <Grid item>
        <div className="logo-beta" onClick={() => window.location.href='/'}>Beta Version</div>
      </Grid>
      <Grid item>
        <IconButton onClick={handleMenuOpen}>
          <MenuIcon sx={{ color: "white"}} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {menuItems.filter(item => item.path !== path).map(item => (
            <MenuItem 
              key={item.path} 
              className="custom-menu-item" 
              onClick={() => { handleMenuClose(); window.location.href=item.path;}}
            >
              {item.icon}
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </Grid>
  );
}

export default Header;
