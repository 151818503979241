import React from 'react';
import { Container, Grid, Typography, Paper, Button } from '@mui/material';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TranslateIcon from '@mui/icons-material/Translate';
import LanguageIcon from '@mui/icons-material/Language';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AssistantIcon from '@mui/icons-material/Assistant';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

import '../App.css';

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const AboutContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  color: '#fff',
}));

const SolutionBlock = styled(Paper)(() => ({
  backgroundColor: 'transparent',
  fontFamily: 'Poppins',
  borderRadius: '8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    cursor: 'pointer',
  },
}));

const IconButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    borderRadius: '8px',
    padding: theme.spacing(1),
    background: 'linear-gradient(45deg, #68aeec, #3c5871)',
    boxShadow: '0 3px 5px 2px rgba(105, 135, 255, .3)',
    '&:hover': {
      background: 'linear-gradient(45deg, #5a9bd8, #35485c)',
    },
  }));

const About = () => {
  return (
    <ThemeProvider theme={theme}>
      <AboutContainer maxWidth="md">
        <Typography variant="h4" gutterBottom sx={{ marginBottom: 4 }}>
          About the Project
        </Typography>
        <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
          Welcome to the Darija Toolkit, a suite of resources designed to integrate the Moroccan dialect, Darija, into any application. Our goal is to dismantle language barriers, making Darija accessible and functional across all digital platforms. We offer high-quality tools and services for translation and transliteration, along with REST APIs for seamless integration and optimal performance, enabling easy incorporation of Darija into your projects.
        </Typography>
        <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
          Additionally, our regularly updated repository of Darija NLP resources is an invaluable asset for researchers, developers, and professionals working with the Moroccan dialect.
        </Typography>
        <Grid container spacing={4} sx={{ marginTop: 4 }}>
          <Grid item xs={12} sm={6} md={4} onClick={() => window.location.href = '/'}>
            <SolutionBlock elevation={3}>
            <IconButton startIcon={<TranslateIcon />} fullWidth>
              <Typography variant="h6">Darija to English</Typography>
            </IconButton>
            </SolutionBlock>
          </Grid>
          <Grid item xs={12} sm={6} md={4} onClick={() => window.location.href = '/backtranslation'}>
            <SolutionBlock elevation={3}>
            <IconButton startIcon={<LanguageIcon />} fullWidth>
              <Typography variant="h6">English to Darija</Typography>
            </IconButton>
            </SolutionBlock>
          </Grid>
          <Grid item xs={12} sm={6} md={4} onClick={() => window.location.href = '/transliteration'}>
            <SolutionBlock elevation={3}>
            <IconButton startIcon={<SwapHorizIcon />} fullWidth>
              <Typography variant="h6">Transliteration</Typography>
            </IconButton>
            </SolutionBlock>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SolutionBlock elevation={3} onClick={() => window.location.href = '/chat'}>
            <IconButton startIcon={<AssistantIcon />} fullWidth>
              <Typography variant="h6">Smart Assistant</Typography>
            </IconButton>
            </SolutionBlock>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SolutionBlock elevation={3} onClick={() => window.open('https://github.com/darija-open-dataset/dataset', '_blank')}>
            <IconButton startIcon={<DataUsageIcon />} fullWidth>
              <Typography variant="h6">Data</Typography>
            </IconButton>
            </SolutionBlock>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SolutionBlock elevation={3} onClick={() => window.location.href = '/darija-resources'}>
            <IconButton startIcon={<AutoAwesomeMotionIcon />} fullWidth>
              <Typography variant="h6">Resources</Typography>
            </IconButton>
            </SolutionBlock>
          </Grid>
        </Grid>
        <footer className="App-footer">
            Developed by <a href="https://www.linkedin.com/in/aissam-outchakoucht/" target="_blank" rel="noopener noreferrer">Aissam Out, 2024</a>
        </footer>
      </AboutContainer>
    </ThemeProvider>
  );
};

export default About;
