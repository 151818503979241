import React, { useState } from 'react';
import { Container, Typography, Card, CardContent, CardMedia, Grid, Button, Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif;',
  },
});

const MainContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
  color: '#fff',
}));

const useStyles = makeStyles(() => ({
  root: {
    color: 'white',
  },
  title: {
    marginTop: '2%',
  },
  card: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: '#fff',
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '90%',
    width: '90%',
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
  media: {
    height: 100,
    padding: theme.spacing(1),
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  gridItem: {
    flex: '1 1 100%',
    boxSizing: 'border-box',
    maxWidth: '33.33%',
    '@media (max-width: 960px)': {
      maxWidth: '50%',
    },
    '@media (max-width: 600px)': {
      maxWidth: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    color: '#fff',
    backgroundColor: '#4974b8',
    '&:hover': {
      backgroundColor: '#303f9f',
      color: '#fff',
    },
  },
}));

const projects = [
  { 
    name: 'Darija Open Dataset (DODa)', 
    description: 'An open-source project dedicated to the Moroccan dialect. With about 150,000 entries, DODa is arguably the largest open-source collaborative project for Darija <=> English translation built for Natural Language Processing purposes.', 
    url: 'https://github.com/darija-open-dataset/dataset',
    image: '/doda.png',
  },
  { 
    name: 'AtlasIA', 
    description: 'A community of engineers, researchers, professors, students and professionals building the next generation of customized, open, Moroccan AI models aligned with Moroccan values, identity and culture.', 
    url: 'https://atlasia.ma/',
    image: '/atlasia.png',
  },
  { 
    name: 'Darija Distance', 
    description: 'DarijaDistance is a specialized Python library crafted to handle the unique linguistic nuances of Moroccan Darija. It offers powerful tools for word comparison, distance measurement, translation lookup, and more; making it a valuable tool for natural language processing (NLP) tasks involving Darija.', 
    url: 'https://pypi.org/project/DarijaDistance/',
    image: '/dardist.png',
  },
  {
    name: 'Wikipedia b Darija',
    description: 'Wikipedia in Moroccan Darija. With more than 10,000 articles, it is the largest collection of Moroccan Darija articles available online.',
    url: 'https://ary.wikipedia.org/wiki',
    image: '/wiki.png',
  },
  {
    name: 'Goud.ma News Dataset',
    description: 'Goud dataset contains 158k articles and their headlines extracted from Goud.ma news website.',
    url: 'https://github.com/issam9/goud-summarization-dataset',
    image: '/goud.png',
  },
  {
    name: 'Tagine Qui Parle',
    description: 'A searchable online dictionary plus grammar & pronunciation guides',
    url: 'https://tajinequiparle.com/en/home-2/',
    image: '/tqp.png',
  },
  {
    name: 'Darija Wordnet',
    description: 'Moroccan Darija Wordnet (MDW) using a bilingual Moroccan-English dictionary.',
    url: 'https://infoscience.epfl.ch/entities/publication/5f17dddb-4e3d-4949-b0f5-2cfde81b6bb1',
    image: '/image.png',
  },
  {
    name: 'CAMeL Tools',
    description: 'CAMeL Tools is suite of Arabic natural language processing tools developed by the CAMeL Lab at New York University Abu Dhabi.',
    url: 'https://github.com/CAMeL-Lab/camel_tools',
    image: '/camel.png',
  },
  {
    name: 'SAFAR',
    description: 'SAFAR is a platform dedicated to ANLP (Arabic Natural Language Processing). It is open source, cross-platform, modular, and provides an integrated development environment (IDE).',
    url: 'http://arabic.emi.ac.ma/safar/',
    image: '/safar.png',
  },
  {
    name: 'MADAR Corpus',
    description: 'The MADAR corpus is a collection of parallel sentences covering the dialects of 25 cities from the Arab World, in addition to English, French, and MSA. The list of Arab cities covered in the MADAR corpus includes Fes, Rabat, Algiers, and Tunis.',
    url: 'https://camel.abudhabi.nyu.edu/madar-parallel-corpus/',
    image: '/image.png',
  },
  
  {
    name: 'LID Language Identification',
    description: 'A corpus containing 34k Moroccan Colloquial Arabic sentences collected from different sources.',
    url: 'http://arabic.emi.ac.ma/alelm/?page_id=273/',
    image: '/image.png',
  },
  {
    name: 'A Dict of Moroccan Arabic',
    description: 'A printed dictionary by Richard S. Harrell that focuses on the essential vocabulary used in daily life. It does not aim to include dialect variations or specialized terminologies.',
    url: 'https://ia804708.us.archive.org/35/items/ADictionaryOfMoroccanArabicMoroccanEnglishEnglishMoroccan/A%20Dictionary%20of%20Moroccan%20Arabic%20Moroccan-English%20%26%20English-Moroccan_text.pdf',
    image: '/dict.png',
  },
  {
    name: 'Le Dictionnaire COLIN',
    description: 'A major reference work for any library concerned with North African languages and dialects! 8 hard cover volumes.',
    url: 'https://www.ketabook.com/en-fr/products/le-dictionnaire-colin-darabe-dialectal-marocain-8-volumes',
    image: '/colin.png',
  },
  {
    name: 'DART',
    description: 'Manually-annotated multi-dialect dataset of Arabic tweets. The Dialectal ARabic Tweets (DART) dataset has about 25K tweets that are annotated via crowdsourcing and it is well-balanced over five main groups of Arabic dialects: Egyptian, Maghrebi, Levantine, Gulf, and Iraqi.',
    url: 'https://aclanthology.org/L18-1579.pdf',
    image: '/image.png',
  },
  {
    name: 'MADED',
    description: 'Moroccan Arabic Dialect Electronic Dictionary (MADED) is an electronic lexicon containing almost 11.500 entries.',
    url: 'http://arabic.emi.ac.ma/alelm/?page_id=273/',
    image: '/image.png',
  },
  {
    name: 'ASAYAR',
    description: 'A Dataset for Arabic-Latin Scene Text Localization in Highway Traffic Panels',
    url: 'https://vcar.github.io/ASAYAR/',
    image: '/sayar.png',
  },
  
];

const DarijaResources = () => {
  const classes = useStyles();
  const [expandedProject, setExpandedProject] = useState(null);

  const handleExpandClick = (project) => {
    setExpandedProject(project);
  };

  const handleClose = () => {
    setExpandedProject(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <MainContainer maxWidth="md" className={classes.root}>
        <Typography variant="h4" component="h1" gutterBottom className={classes.title}>
          Darija Resources
        </Typography>
        <Typography variant="body1" paragraph>
          This page covers all related projects that treat Darija in some way, including data, tools, and more.
        </Typography>
        <Grid container className={classes.gridContainer}>
          {projects.map((project, index) => (
            <Grid
              item
              key={project.name}
              className={classes.gridItem}
              onClick={() => handleExpandClick(project)}
            >
              <Card className={classes.card} sx={{ paddingBottom: 0 }}>
                <CardMedia
                  className={classes.media}
                  image={project.image}
                  title={project.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {project.name}
                  </Typography>
                  <Typography variant="body2" className={classes.description}>
                    {project.description}
                  </Typography>
                  <Grid className={classes.buttonContainer}>
                    <Button
                      size="small"
                      className={classes.button}
                      sx={{ backgroundColor: '#4974b8', color: '#fff', marginTop:1, width:'100%', '&:hover': { backgroundColor: '#303f9f' } }}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(project.url, '_blank');
                      }}
                    >
                      Visit Website
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {expandedProject && (
          <Dialog open={true} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogContent>
              <Typography variant="h5" gutterBottom display={'flex'} justifyContent={'center'} alignItems={'center'}>
                {expandedProject.name}
              </Typography>
              <CardMedia
                className={classes.media}
                image={expandedProject.image}
                title={expandedProject.name}
              />
              <Typography variant="body2" paragraph sx={{marginTop:1, marginBottom:1}}>
                {expandedProject.description}
              </Typography>
              <Grid className={classes.buttonContainer}>
                <Button
                  size="small"
                  className={classes.button}
                  sx={{ backgroundColor: '#4974b8', color: '#fff', width:'100%', '&:hover': { backgroundColor: '#303f9f' } }}
                  onClick={() => window.open(expandedProject.url, '_blank')}
                >
                  Visit Website
                </Button>
              </Grid>
            </DialogContent>
          </Dialog>
        )}
        <footer className="App-footer">
          Developed by <a href="https://www.linkedin.com/in/aissam-outchakoucht/" target="_blank" rel="noopener noreferrer">Aissam Out, 2024</a>
        </footer>
      </MainContainer>
    </ThemeProvider>
  );
};

export default DarijaResources;
