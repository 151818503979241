import React, { useState } from 'react';
import './App.css';
import { FaThumbsUp, FaThumbsDown, FaTimes } from 'react-icons/fa';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Transliteration from './Components/Transliteration';
import BackTranslation from './Components/Backtranslation';
import Header from './Components/Header';
import About from './Components/About';
import ChatWindow from './Components/Chat';
import DarijaResources from './Components/DarijaResources';

function App() {
  const [text, setText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [error, setError] = useState(null); // State to handle translation errors
  const [inputError, setInputError] = useState(''); // New state for input errors
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [feedbackGiven, setFeedbackGiven] = useState(null); 
  const [showError, setShowError] = useState(true);
  const [lastTranslatedTextID, setLastTranslatedTextID] = useState(''); // New state to store the last translated text
  const [ipAddress, setIPAddress] = useState('')
  const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const path = window.location.pathname;

  React.useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))
  }, []);
  
  const handleThumbsUp = () => {
    console.log('Thumbs up clicked!');
    setFeedbackGiven('up');
    // send feedback to the server as {text, translatedText, feedback: 'up'}
    try {
      fetch(BASE_URL + '/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "id": lastTranslatedTextID, "feedback": "up" }),
      });
    }
    catch (error) {
      console.error('Error sending feedback', error);
    }
  };

  const handleThumbsDown = () => {
    console.log('Thumbs down clicked!');
    setFeedbackGiven('down');
    // send feedback to the server as {text, translatedText, feedback: 'down'}
    try {
      fetch(BASE_URL + '/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "id": lastTranslatedTextID, "feedback": "down" }),
      });
    }
    catch (error) {
      console.error('Error sending feedback', error);
    }
  };

  const handleCloseError = () => {
    setShowError(false); // Hide the error banner when close button is clicked
    setError(null); // Optionally clear the error message
  };

  const handleTranslate = async () => {
    if (!text) return; // Don't call the API if the text is empty
    setError(null); // Reset the translation error state
    setInputError(''); // Reset the input error state
    setIsLoading(true); // Start loading

    try {
      const response = await fetch(BASE_URL + '/translate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // sent text and IP address to the server
        body: JSON.stringify({ text, ipAddress }),
      });

      // Check if the response status is 429 or other client/server errors (status >= 400)
      if (!response.ok) {
        setShowError(true); // Show the error banner
        if (response.status === 429) {
          throw new Error("Request limit exceeded"); // Use the custom error message from the backend
        } else if (response.status === 400) {
          const errorData = await response.json();
          throw new Error(errorData.error); // Use the error message from the backend
        }
        else {
          // Handle other errors
          throw new Error("An error occurred during translation.");
        }
      }
  
      const data = await response.json();
      setFeedbackGiven(null); // Reset the feedback state
      setTranslatedText(data.translatedText);
      setLastTranslatedTextID(data.id); // Store the last translated text
    } catch (error) {
      setError(error.message !== 'Failed to fetch' ? error.message : 'An error occurred during translation.');
    } finally {
      // setText(''); // Optionally clear the input field after translating
      setIsLoading(false); // Stop loading
    }
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length > 100) {
      // Set input error if the length exceeds 20 characters
      setInputError('Input cannot exceed 100 characters.');
    } else {
      setInputError(''); // Clear input error if within limit
      setText(inputText);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !inputError) {
      handleTranslate();
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <Router>
      <div className="App">
        <Header
          anchorEl={anchorEl}
          handleMenuOpen={handleMenuOpen}
          handleMenuClose={handleMenuClose}
          path={path}
        />
        <Routes>
          <Route exact path="/" element={
            <div>
              <div className="App-main">
                <div className="Image-main-container">
                  <img className="Image-main" src="/image.png" alt="Spinning main logo" />
                  <div className="text-bubble"><span role="img" aria-label="Lightbulb">💡</span> This model translates from Darija to English, supporting both Arabic and Latin alphabets. Currently, the Latin alphabet provides better translation results.</div>
                </div>

                {error && showError && (
                  <div className="error-banner">
                    {error}
                    <button className="error-close-btn" onClick={handleCloseError}>
                      <FaTimes />
                    </button>
                  </div>
                )}

                <div className="translate-container">
                  <div className="input-spinner-container">
                    <input
                      className="translate-input"
                      type="text"
                      value={text}
                      placeholder="Enter Darija text to translate..."
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      autoFocus
                    />
                    {isLoading && <div className="loading-spinner"></div>}
                  </div>
                  <button className="translate-button" onClick={handleTranslate} disabled={!!inputError || isLoading}>Translate</button>
                </div>
                {inputError && <div className="input-error">{inputError}</div>}
                <div className="translation-container">
                  <div className="translation-box">
                    <span role="img" aria-label="Morocco Flag">🇲🇦🇺🇸 </span>
                    {translatedText}
                  </div>
                  {translatedText && (
                    <div>
                      <button className="feedback-button" disabled={feedbackGiven === 'down'} onClick={handleThumbsUp}><FaThumbsUp /></button>
                      <button className="feedback-button" disabled={feedbackGiven === 'up'} onClick={handleThumbsDown}><FaThumbsDown /></button>
                    </div>
                  )}
                </div>
              </div>
              <footer className="App-footer">
                Developed by <a href="https://www.linkedin.com/in/aissam-outchakoucht/" target="_blank" rel="noopener noreferrer">Aissam Out, 2024</a>
              </footer>
            </div>
          } />
          <Route path="/transliteration" element={<Transliteration />} />
          <Route path="/backtranslation" element={<BackTranslation />} />
          <Route path="/about" element={<About />} />
          <Route path="/chat" element={<ChatWindow />} />
          <Route path="/darija-resources" element={<DarijaResources />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
