import React from 'react';
import {
  Box,
  TextField,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Modal,
  Snackbar,
  Alert,
} from '@mui/material';
import '../App.css';
import './chat.css';

const ChatWindow = () => {
  const [messages, setMessages] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [ipAddress, setIPAddress] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [isTokenModalOpen, setIsTokenModalOpen] = React.useState(true);
  const [notification, setNotification] = React.useState({ open: false, message: '', severity: '' });
  const messagesEndRef = React.useRef(null);
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  React.useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error));
  }, []);

  React.useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  React.useEffect(() => {
    const storedToken = localStorage.getItem('chatToken');
    if ( storedToken !== null && storedToken !== undefined) {
      setToken(storedToken);
      setIsTokenModalOpen(false);
    } else {
      setIsTokenModalOpen(true);
    }
  }, []);

  const handleSendToken = () => {
    try {
      fetch(BASE_URL + '/chatoken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({ ipAddress }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            setToken('');
            setNotification({ open: true, message: 'Invalid Token', severity: 'error' });
          } else {
            setToken(data.token);
            setIsTokenModalOpen(false);
            localStorage.setItem('chatToken', data.token);
            setNotification({ open: true, message: 'Token Accepted', severity: 'success' });
          }
        })
        .catch(error => {
          console.error('Error sending token:', error);
          setNotification({ open: true, message: 'Error Sending Token', severity: 'error' });
        });
    } catch (error) {
      console.error('Error sending token:', error);
      setNotification({ open: true, message: 'Error Sending Token', severity: 'error' });
    }
  };

  const handleSendMessage = async () => {
    setIsLoading(true);
    if (inputValue.trim()) {
      const userMessage = { text: inputValue, sender: 'user' };
      setMessages([...messages, userMessage]);
      setInputValue('');
      try {
        const response = await fetch(BASE_URL + '/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({ inputValue, messages, ipAddress }),
        });

        if (response.ok) {
          const data = await response.json();
          const botMessage = { text: data.response, sender: 'Bot' };
          setMessages(prevMessages => [...prevMessages, botMessage]);
        } else {
          console.error('Failed to get response from backend');
          setNotification({ open: true, message: 'Failed to get response from backend', severity: 'error' });
        }
      } catch (error) {
        console.error('Error sending message:', error);
        setNotification({ open: true, message: error, severity: 'error' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <div>
      <Modal open={isTokenModalOpen} onClose={() => {}} >
        <Box className="Modal-box">
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter your token here..."
            value={token}
            onChange={(e) => setToken(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleSendToken();
              }
            }}
            autoFocus
            InputProps={{
              style: { fontFamily: 'Poppins', backgroundColor: '#f5f5f5' },
              endAdornment: (
                <img
                  onClick={handleSendToken}
                  className={isLoading ? 'Image-send-spin' : 'Image-send'}
                  src="/image-send.png"
                  alt="Send"
                  style={{ cursor: 'pointer', marginLeft: '8px' }}
                />
              ),
            }}
          />
        </Box>
      </Modal>
      
      <Paper elevation={3} className="chat-window" style={{ filter: isTokenModalOpen ? 'blur(8px)' : 'none' }}>
        <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2 }}>
          <List>
            {messages.map((message, index) => (
              <ListItem 
                key={index} 
                sx={{
                  display: 'flex', 
                  justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                }}
              >
                <Box className='message-box'
                  sx={{ backgroundColor: message.sender === 'user' ? '#DCF8C6' : '#E3F2FD' }}>
                  <ListItemText 
                    primary={
                      <Typography variant="body1" sx={{ fontSize: '14px', fontFamily: 'Poppins' }}>
                        {message.text}
                      </Typography>
                    }
                  />
                </Box>
              </ListItem>
            ))}
            <div ref={messagesEndRef} />
          </List>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 1 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Ewa..."
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleSendMessage();
              }
            }}
            autoFocus
            InputProps={{ style: { fontFamily: 'Poppins', backgroundColor: '#f5f5f5' }} }
          />
          
          <Grid onClick={handleSendMessage} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <img 
              className={isLoading ? 'Image-send-spin' : 'Image-send'}
              src="/image-send.png" 
              alt="Spinning main logo" 
            />
          </Grid>
        </Box>
        <footer className="App-footer">
          Developed by <a href="https://www.linkedin.com/in/aissam-outchakoucht/" target="_blank" rel="noopener noreferrer">Aissam Out, 2024</a>
          <img  src="/image-send.png" alt="Spinning main logo" 
              style={{ marginLeft: '5px', width: '10px', height: '10px' }}
              onClick={ () => { localStorage.removeItem('chatToken'); window.location.reload();}}
          />
        </footer>
      </Paper>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ChatWindow;
