// Transliteration.js
import React, { useState } from 'react';
import { FaThumbsUp, FaThumbsDown, FaTimes } from 'react-icons/fa';


function Transliteration() {
  const [text, setText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [error, setError] = useState(null);
  const [inputError, setInputError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackGiven, setFeedbackGiven] = useState(null); 
  const [showError, setShowError] = useState(true);
  const [lastTranslatedTextID, setLastTranslatedTextID] = useState('');
  const [ipAddress, setIPAddress] = useState('');
  const [araToLat, setAraToLat] = useState(true);
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  React.useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))
  }, []);
  
  const handleThumbsUp = () => {
    console.log('Thumbs up clicked!');
    setFeedbackGiven('up');
    try {
      fetch(BASE_URL + '/transliteration-feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "id": lastTranslatedTextID, "feedback": "up" }),
      });
    }
    catch (error) {
      console.error('Error sending feedback', error);
    }
  };

  const handleThumbsDown = () => {
    console.log('Thumbs down clicked!');
    setFeedbackGiven('down');
    try {
      fetch(BASE_URL + '/transliteration-feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "id": lastTranslatedTextID, "feedback": "down" }),
      });
    }
    catch (error) {
      console.error('Error sending feedback', error);
    }
  };

  const handleCloseError = () => {
    setShowError(false);
    setError(null);
  };

  const handleTranslate = async () => {
    if (!text) return;
    setError(null);
    setInputError('');
    setIsLoading(true);

    try {
      const response = await fetch(BASE_URL + '/transliterate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text, ipAddress, araToLat }),
      });

      if (!response.ok) {
        setShowError(true);
        if (response.status === 429) {
          throw new Error("Request limit exceeded");
        } else if (response.status === 400) {
          const errorData = await response.json();
          throw new Error(errorData.error);
        } else {
          throw new Error("An error occurred during transliteration.");
        }
      }
  
      const data = await response.json();
      setFeedbackGiven(null);
      setTranslatedText(data.translatedText);
      setLastTranslatedTextID(data.id);
    } catch (error) {
      setError(error.message !== 'Failed to fetch' ? error.message : 'An error occurred during transliteration.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length > 100) {
      setInputError('Input cannot exceed 100 characters.');
    } else {
      setInputError('');
      setText(inputText);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !inputError) {
      handleTranslate();
    }
  };

  const handleSwitchChange = (e) => {
    setAraToLat(e.target.checked);
  }

  return (
    <div className="App">
      <div className="App-main">
        <div className="Image-main-container">
          <img className="Image-main" src="/image.png" alt="Spinning main logo" />
          <div className="text-bubble"><span role="img" aria-label="Lightbulb">💡</span> 
            This model transliterates Darija between Latin and Arabic scripts. Use the switch to select the desired direction.
          </div>
        </div>
        {error && showError && (
          <div className="error-banner">
            {error}
            <button className="error-close-btn" onClick={handleCloseError}>
              <FaTimes />
            </button>
          </div>
        )}


        <div className="translate-container">
          <div className="input-spinner-container">
            <input
              className="translate-input"
              type="text"
              placeholder={araToLat ? "Convert Arabic script to Latin..." : "Convert Latin script to Arabic..."}
              value={text}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              autoFocus
            />
            {isLoading && <div className="loading-spinner"></div>}
          </div>
          <button className="translate-button" onClick={handleTranslate} disabled={!!inputError || isLoading}>Transliterate</button>
          <div className="switch-container">
            <label className="switch">
              <input type="checkbox" checked={araToLat} onChange={handleSwitchChange} />
              <span className="slider"></span>
            </label>
          </div>
        </div>
        {inputError && <div className="input-error">{inputError}</div>}
        <div className="translation-container">
          <div className="translation-box">
            <span role="img" aria-label="Morocco Flag">{araToLat ? "🇲🇦🇺🇸" : "🇺🇸🇲🇦"}</span>
            {translatedText}
          </div>
          {translatedText && (
            <div>
              <button className="feedback-button" disabled={feedbackGiven === 'down'} onClick={handleThumbsUp}><FaThumbsUp /></button>
              <button className="feedback-button" disabled={feedbackGiven === 'up'} onClick={handleThumbsDown}><FaThumbsDown /></button>
            </div>
          )}
        </div>
      </div>
      <footer className="App-footer">
        Developed by <a href="https://www.linkedin.com/in/aissam-outchakoucht/" target="_blank" rel="noopener noreferrer">Aissam Out, 2024</a>
      </footer>
    </div>
  );
}

export default Transliteration;
